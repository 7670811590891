import React, { useState } from 'react';
import axios from 'axios';
import Loader from './Loader';

export const Blend = () => {
  const [imageUrls, setImageUrls] = useState(['', '']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [blendedImage, setBlendedImage] = useState(null);

  const handleImageUrlChange = (index, url) => {
    const newUrls = [...imageUrls];
    newUrls[index] = url;
    setImageUrls(newUrls);
  };

  const handleBlendSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('https://generationapi.syntaxseeker.com/blend1/', {
        images: imageUrls
      });

      if (response.data && response.data.job_id) {
        const jobId = response.data.job_id;
        pollJobStatus(jobId);
      } else {
        throw new Error('No job ID returned from the server.');
      }
    } catch (error) {
      console.error('Error initiating blend:', error);
      setError(error.response?.data?.detail || 'Failed to initiate blend. Please try again.');
      setLoading(false);
    }
  };

  const pollJobStatus = (jobId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`https://generationapi.syntaxseeker.com/job_status/${jobId}`);
        if (response.data) {
          const jobStatus = response.data.status;
          if (jobStatus === 'SUCCEEDED') {
            setBlendedImage(response.data.result);
            clearInterval(intervalId);
            setLoading(false);
          } else if (jobStatus === 'FAILED') {
            setError('Blend failed. Please try again.');
            clearInterval(intervalId);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching job status:', error);
        setError('Failed to fetch job status. Please try again.');
        clearInterval(intervalId);
        setLoading(false);
      }
    }, 50000);
  };

  return (
    <div className="w-full min-h-screen bg-gray-900 text-white flex flex-col items-center relative">
      <div className="w-full max-w-2xl px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-purple-400 text-center">
          Blend Images
        </h1>

        <form onSubmit={handleBlendSubmit} className="w-full mb-8 p-6 bg-gray-800 shadow-md rounded-lg">
          <div className="mb-4">
            <label className="block text-gray-300 text-lg font-semibold mb-2">
              Image URL 1:
            </label>
            <input
              type="text"
              value={imageUrls[0]}
              onChange={(e) => handleImageUrlChange(0, e.target.value)}
              className="border border-gray-700 rounded-lg w-full px-3 py-2 text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 text-lg font-semibold mb-2">
              Image URL 2:
            </label>
            <input
              type="text"
              value={imageUrls[1]}
              onChange={(e) => handleImageUrlChange(1, e.target.value)}
              className="border border-gray-700 rounded-lg w-full px-3 py-2 text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>
          <div className="mb-4 flex items-center space-x-4">
            <button
              type="submit"
              className="bg-purple-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out opacity-50 cursor-not-allowed"
              disabled
            >
              Blend
            </button>
          </div>
        </form>

        {loading && <Loader />}

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline">{error}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError('')}>
              <button
                aria-label="Close error message"
                onClick={() => setError('')}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
              >
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.846 7.349 14.849a1.2 1.2 0 1 1-1.697-1.697L8.303 10 5.652 7.349A1.2 1.2 0 1 1 7.349 5.652L10 8.303l2.651-2.651a1.2 1.2 0 1 1 1.697 1.697L11.697 10l2.651 2.651a1.2 1.2 0 0 1 0 1.697z"/>
                </svg>
              </button>
            </span>
          </div>
        )}

        {blendedImage && (
          <div className="mt-8">
            <h2 className="text-xl font-bold mb-4 text-purple-400">Blended Image:</h2>
            <img src={blendedImage} alt="Blended result" className="w-full rounded-lg" />
          </div>
        )}
      </div>
    </div>
  );
};
